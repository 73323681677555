import React, { FC } from "react";
import AuthMain from "../components/auth/Main/Main";
import { AuthProvider } from "@context/authContext";
import TechnicalWork from "@app/components/technicalWork/TechnicalWork";

const Auth: FC = () => {
	return (
		// <AuthProvider>
		// 	<AuthMain />
		// </AuthProvider>
		<TechnicalWork />
	);
};

export default Auth;
